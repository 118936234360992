// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/v2/theming/

// Font path is used to include ionicons,
// roboto, and noto sans fonts
$font-path: '../assets/fonts';

// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
$app-direction: ltr;

// ===== Brand colours =====

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

// Secondary text options
// #4d4d4e #242424
// graphs and maps colors unused in graphs/maps.ts/html

:root {
  --primary: #4d4d4e;
  --primary-light: #808080;
  --primary-dark: #242424;
  --secondary: #82df95;
  --secondary-light: #d2f3d9;
  --secondary-dark: darken(#82df95, 10%);
  --danger: #f16464;
  --danger-light: lighten(#f16464, 10%);
  --danger-dark: darken(#f16464, 10%);
  --general-light: #f3f3f3;
  --general-dark: #2c2c2c;
  --primary-text: #808080;
  --primary-text-light: #bfc0c2;
  --primary-text-dark: #000000;
  --secondary-text: #808080;
  --secondary-text-light: #808080;
  --secondary-text-dark: #2c2c2c;
  --background-primary: #f3f3f3;
  --background-light: #ffffff;
  --background-dark: #b4b4b4;
  --signal-indicator-bar-empty: #cccccc;
  --signal-indicator-bar-weak: #ff1d25;
  --signal-indicator-bar-medium: #f0ad4e;
  --signal-indicator-bar-strong: #6e6e6e;
  --graphs-background-color: #4bc0c0;
  --graphs-border-color: #3d9c9c;
  --map-circle-color: #97c9af;
  --map-point-color: #d9534f;
  --setup-color: #0085d8;
  --map-offline-point-color: #dcdcdc;
  --map-satellite-point-color: yellow;
  --armed: #7ac943;
  --offline: #ff1d25;
  --toast-text: #e6e6e6;

  // Shared Variables
  // --------------------------------------------------
  // To customize the look and feel of this app, you can override
  // the Sass variables found in Ionic's source scss files.
  // To view all the possible Ionic variables, see:
  // http://ionicframework.com/docs/v2/theming/overriding-ionic-variables/

  // ===== Overrides =====
  --tabs-background: var(--general-dark);
  --tabs-tab-color-active: var(--secondary);
  --tabs-tab-color-inactive: var(--general-light);
  --tabs-md-tab-color-inactive: var(--general-light);
  --fab-content-margin: 10px; // TODO should be defined by default

  // Settings Inputs
  // Removing green underline highlights to inputs that are not selected
  --text-input-highlight-color-valid: None;

  // Button Outlines
  --button-md-outline-border-width: 4px;
  --button-ios-outline-border-width: 4px;

  // Settings segment button
  --segment-button-md-border-color-activated: var(--secondary);

  // Popovers
  --popover-ios-width: 250px;

  // ===== Custom components =====

  --stats-grid-space: 10px;
  --stats-grid-label-fontsize: 0.9em;
  --stats-grid-value-fontsize: 1.24em;
  --stats-grid-unit-fontsize: 0.9em;
  --stats-grid-gps-value-fontsize: 1em;
  --stats-grid-last-updated-fontsize: 0.7em;
  --stats-grid-tile-height: 94px;
  --stats-grid-col-height: --stats-grid-tile-height + 10;
  --stats-grid-closed-height: 208px;
  --stats-grid-closed-height-small: 108px;
  --stats-grid-col-gps-height: 40px;
  --stats-grid-open-btn-height: 20px;

  --set-anchor-value-size: 1.15em;

  --graph-title-fontsize: 1.4em;

  --alert-margin: 10px;

  // Custom breakpoints
  --stats-grid-height-breakpoint: 570px;

  --setup-device-box-height: 75px;
  --setup-device-box-top-margin: 18px;

  --ion-font-family: 'Lato-Regular';
  --ion-font-family-light: 'Lato-Light';
  --ion-font-family-base: 'Lato-Regular';
  --ion-font-family-md-base: 'Lato-Regular';
  --ion-font-family-ios-base: 'Lato-Regular';
  --ion-font-family-wp-base: 'Lato-Regular';
  --ion-font-size-base: 14px;
}

@mixin text-box-styles() {
  border-radius: 4px;
  border: 1px solid var(--primary-text-light);
  padding: 5px;
}

// @import 'roboto';
// @import 'noto-sans';

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here

// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here

// App Windows Variables
// --------------------------------------------------
// Windows only Sass variables can go here

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

// @import 'ionic.theme.default';

// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/v2/ionicons/

// Custom icons

// --------------------------------------------------
// Refer: https://golb.hplar.ch/2018/01/Custom-SVG-icons-in-Ionic.html

ion-icon {
  &[class*='custom-'] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    width: 1em;
    height: 1em;
  }
  &[class*='custom-user'] {
    mask-image: url(../assets/icon/custom-user.svg);
    background: currentColor;
  }
  &[class*='custom-brnkl'] {
    mask-image: url(../assets/icon/custom-brnkl.svg);
    background: currentColor;
  }
  &[class*='custom-devices'] {
    mask-image: url(../assets/icon/custom-devices.svg);
    background: currentColor;
  }
  &[class*='custom-complete'] {
    mask-image: url(../assets/icon/custom-complete.svg);
    background: currentColor;
  }
  &[class*='custom-mate'] {
    mask-image: url(../assets/icon/custom-mate.svg);
    background: currentColor;
  }
  &[class*='custom-satellite'] {
    mask-image: url(../assets/icon/custom-satellite.svg);
    background: currentColor;
  }
  &[class*='custom-keyfob'] {
    mask-image: url(../assets/icon/custom-keyfob.svg);
    background: currentColor;
  }
  &[class*='custom-motion'] {
    mask-image: url(../assets/icon/custom-motion.svg);
    background: currentColor;
  }
  &[class*='custom-multipurpose'] {
    mask-image: url(../assets/icon/custom-multipurpose.svg);
    background: currentColor;
  }
  &[class*='custom-water'] {
    mask-image: url(../assets/icon/custom-water.svg);
    background: currentColor;
  }
  &[class*='custom-keypad'] {
    mask-image: url(../assets/icon/custom-keypad.svg);
    background: currentColor;
  }
  &[class*='custom-add-device'] {
    mask-image: url(../assets/icon/custom-add-device.svg);
    background: currentColor;
  }
  &[class*='custom-add-action'] {
    mask-image: url(../assets/icon/custom-add-action.svg);
    background: currentColor;
  }
  &[class*='custom-remove-action'] {
    mask-image: url(../assets/icon/custom-remove-action.svg);
    background: currentColor;
  }
  &[class*='custom-satellite-service'] {
    mask-image: url(../assets/icon/custom-satellite-service.svg);
    background: currentColor;
  }
  &[class*='custom-wireless'] {
    mask-image: url(../assets/icon/custom-wireless.svg);
    background: currentColor;
  }
  &[class*='custom-find'] {
    mask-image: url(../assets/icon/custom-find.svg);
    background: currentColor;
  }
  &[class*='custom-sensor-remove-tag'] {
    mask-image: url(../assets/icon/custom-sensor-remove-tag.svg);
    background: currentColor;
  }
  &[class*='custom-wired-connection'] {
    mask-image: url(../assets/icon/custom-wired-connection.svg);
    background: currentColor;
  }
  &[class*='custom-scan-code'] {
    mask-image: url(../assets/icon/custom-scan-code.svg);
    background: currentColor;
  }
  &[class*='custom-update'] {
    mask-image: url(../assets/icon/custom-update.svg);
    background: currentColor;
  }
  &[class*='custom-armed'] {
    mask-image: url(../assets/icon/custom-armed.svg);
    background: currentColor;
  }
  &[class*='custom-disarmed'] {
    mask-image: url(../assets/icon/custom-disarmed.svg);
    background: currentColor;
  }
  &[class*='custom-armed-status-bar'] {
    mask-image: url(../assets/icon/custom-armed-status-bar.svg);
    background: currentColor;
  }
  &[class*='custom-offline-status-bar'] {
    mask-image: url(../assets/icon/custom-offline-status-bar.svg);
    background: currentColor;
  }
  &[class*='custom-battery-full'] {
    background-image: url(../assets/icon/custom-battery-full.svg);
    width: 1.15em;
  }
  &[class*='custom-battery-med'] {
    background-image: url(../assets/icon/custom-battery-med.svg);
    width: 1.15em;
  }
  &[class*='custom-battery-low'] {
    background-image: url(../assets/icon/custom-battery-low.svg);
    width: 1.15em;
  }
  &[class*='custom-door-window-sensors'] {
    mask-image: url(../assets/icon/custom-door-window-sensors.svg);
    background: currentColor;
  }
  &[class*='custom-sengled-door-sensor'] {
    mask-image: url(../assets/icon/custom-sengled-door-sensor.svg);
    background: currentColor;
  }
  &[class*='custom-brnkl-door-sensor'] {
    mask-image: url(../assets/icon/custom-brnkl-door-sensor.svg);
    background: currentColor;
  }
  &[class*='custom-forward-navigation'] {
    mask-image: url(../assets/icon/custom-forward-navigation.svg);
    background: currentColor;
  }
  &[class*='custom-back-navigation'] {
    mask-image: url(../assets/icon/custom-back-navigation.svg);
    background: currentColor;
  }
  &[class*='custom-bluetooth'] {
    mask-image: url(../assets/icon/custom-bluetooth.svg);
    background: currentColor;
  }
  &[class*='custom-bluetooth-connected'] {
    mask-image: url(../assets/icon/custom-bluetooth-connected.svg);
    background: currentColor;
  }
  &[class*='custom-bluetooth-disconnected'] {
    mask-image: url(../assets/icon/custom-bluetooth-disconnected.svg);
    background: currentColor;
  }
  &[class*='custom-no-mobile-connection'] {
    mask-image: url(../assets/icon/custom-no-mobile-connection.svg);
    background: currentColor;
  }
  &[class*='custom-mobile-connection'] {
    mask-image: url(../assets/icon/custom-mobile-connection.svg);
    background: currentColor;
  }
  &[class*='custom-satellite-connection'] {
    mask-image: url(../assets/icon/custom-satellite-connection.svg);
    background: currentColor;
  }
  &[class*='custom-change-plan'] {
    mask-image: url(../assets/icon/custom-change-plan.svg);
    background: currentColor;
  }
  &[class*='custom-subscribe'] {
    mask-image: url(../assets/icon/custom-subscribe.svg);
    background: currentColor;
  }
  &[class*='custom-temp-sensor'] {
    mask-image: url(../assets/icon/custom-temp-sensor.svg);
    background: currentColor;
  }
  &[class*='custom-magnet-switch'] {
    mask-image: url(../assets/icon/custom-magnet-switch.svg);
    background: currentColor;
  }
}

// Fonts
// --------------------------------------------------

@font-face {
  font-family: 'Lato-Regular';
  src: url('../assets/fonts/lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato-Light';
  src: url('../assets/fonts/lato/Lato-Light.ttf');
}

@font-face {
  font-family: 'Lato-Black';
  src: url('../assets/fonts/lato/Lato-Black.ttf');
}

// Light mode
:root {
  .toolbar-title {
    --color: var(--primary-dark) !important;
  }

  .toolbar-button {
    --color: var(--primary-dark) !important;
    color: var(--primary-dark) !important;
  }
}

// Dark mode
@media (prefers-color-scheme: dark) {
  :root {
    .toolbar-background {
      --background: #2c2c2c !important;
    }

    .toolbar-title {
      --color: #f3f3f3 !important;
    }

    .toolbar-button {
      --color: #f3f3f3 !important;
      color: #f3f3f3 !important;
    }

    .armed-button-title {
      --color: #f3f3f3 !important;
    }

    .signal-indicator-offline {
      --color: #808080 !important;
    }

    .disarmed-button {
      --color: white !important;
    }

    .disarmed-faded {
      --opacity: 0.6 !important;
    }

    .satellite-indicator {
      --color: white !important;
    }
  }
}
